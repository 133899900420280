// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, CanLoadFn, CanMatchFn, Data, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';
import { showNotAuthorisedModal } from 'src/app/shared/util';
import { UserService } from 'src/app/user/user.service';

import { AuthService } from './auth.service';

export const canActivate: CanActivateFn = (route, state) => _checkAdmin(route.data);

export const canActivateChild: CanActivateChildFn = (childRoute, state) => _checkAdmin(childRoute.data);

export const canMatch: CanMatchFn = (route, segments) => _checkAdmin(route.data);

// FIXME: We should be using `canMatch` but `canMatch` guard runs on every route navigation. Where as `canLoad` that
// we have been using before angular17 upgrade only runs once. We need to review our `canMatch` func declaration. ATM
// when it runs on every route navigation, it fires off observable emissions that was previously assumed to only fire
// once.
export const canLoad: CanLoadFn = (route, segments) => _checkAdmin(route.data);

const _checkAdmin = (data: Data): Observable<boolean> => {
  const authService = inject(AuthService);
  const bsModalService = inject(BsModalService);
  const userService = inject(UserService);
  const router = inject(Router);
  return authService.isAdmin$().pipe(
    take(1),
    mergeMap(isAdmin => (isAdmin ? of(true) : _checkRole(data, bsModalService, userService, router))),
  );
};

const _checkRole = (
  data: Data,
  bsModalService: BsModalService,
  userService: UserService,
  router: Router,
): Observable<boolean> => {
  const stream$ = !data || !data.roles || !data.roles.length ? of(false) : userService.hasRole$(data.roles);
  return stream$.pipe(
    tap((hasRole: boolean) => {
      if (!hasRole) {
        showNotAuthorisedModal(bsModalService, router);
      }
    }),
  );
};
